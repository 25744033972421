import React from "react";
import "./Newstricker.css";


const Newstricker = () => {
    return (
      <div className="news-ticker-container">
        <div className="news-ticker">
        <p>
            upcoming program alert
            <a
              href="https://lagos-city-high-school-festival.vercel.app/"
              // href="https://www.kingsforms.online/teensvoice-community"
              target=""
              rel="noopener noreferrer"
            >
              Click Here to Register!!
            </a>
          </p>
        </div>
      </div>
    );
}

export default Newstricker;